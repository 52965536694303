import * as React from "react";
import tw from "twin.macro";

const List = tw.div`flex flex-col layout-space-y text-default`;

List.Item = tw.div`flex p-1 layout-margin-px`;
List.TextCol = tw.div`flex flex-col flex-grow space-y-0.5`;
List.Title = tw.div`text-body-lg`;
List.Body = tw.div`text-body-md text-default/80`;
List.MetaCol = tw.div`text-body-sm`;

export default List;
