import * as React from "react";
import { Link, navigate } from "gatsby";
import "twin.macro";
import { MdMenu } from "react-icons/md";
import { DataContext } from "../components/Database";
import { ChatBubble } from "../components/icons";
import Layout from "../components/Layout";
import TopAppBar from "../components/TopAppBar";
import Fab from "../components/Fab";
import List from "../components/List";
import { niceDate } from "../utils/date";
import { createConversation, useConversations } from "../data/conversation";
import { usePrimaryLanguage } from "../hooks/settings";
import useOverviewInterface from "../hooks/useOverviewInterface";
import Menu from "../components/Menu";

// markup
const IndexPage = () => {
  const { db } = React.useContext(DataContext);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [primaryLanguage] = usePrimaryLanguage();
  const conversations = useConversations();
  const interfaceLabels = useOverviewInterface(primaryLanguage);

  const newConversation = (e) => {
    e.preventDefault();
    const collection = db.getCollection("conversation");
    createConversation(collection, {
      callback(id) {
        navigate(`/conversation?id=${id}`);
      },
    });
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen((current) => !current);
  };
  return (
    <Layout>
      <Menu open={menuOpen} onClose={closeMenu} />
      <TopAppBar tw="fixed left-0 top-0 w-screen bg-bg">
        <TopAppBar.Icon>
          <MdMenu onClick={toggleMenu} />
        </TopAppBar.Icon>
        <TopAppBar.Title align="center">
          {interfaceLabels.allConversations}
        </TopAppBar.Title>
      </TopAppBar>
      <Fab
        tw="fixed right-1 bottom-1 bg-primary text-on-primary"
        onClick={newConversation}
      >
        <ChatBubble />
        <Fab.Text>{interfaceLabels.startConversation}</Fab.Text>
      </Fab>
      <div tw="py-9">
        <List>
          {conversations.map((conversation) => (
            <List.Item
              key={conversation.id}
              as={Link}
              to={`/conversation?id=${conversation.id}`}
            >
              <List.TextCol tw="overflow-hidden pr-2">
                <List.Title tw="line-clamp-2">
                  {conversation.title ||
                    (conversation.bubbles.length
                      ? conversation.bubbles[conversation.bubbles.length - 1].t[
                          primaryLanguage
                        ]
                      : interfaceLabels.untitled)}
                </List.Title>
                <List.Body>{conversation.categories.join(", ")}</List.Body>
              </List.TextCol>
              <List.MetaCol>
                <div tw="mt-[6px]">
                  {niceDate(
                    new Date(
                      conversation.meta.updated || conversation.meta.created,
                    ),
                  )}
                </div>
              </List.MetaCol>
            </List.Item>
          ))}
        </List>
      </div>
    </Layout>
  );
};

export default IndexPage;
