const ONE_DAY = 1000 * 60 * 60 * 24;

type SomeDateMethod = Date["getDate" | "getMonth" | "getFullYear"];

export function isSameDay(value: Date, today: Date): boolean {
  const methods: SomeDateMethod[] = [
    "getDate" as keyof Date["getDate"],
    "getMonth" as keyof Date["getMonth"],
    "getFullYear" as keyof Date["getFullYear"],
  ];
  for (let i = 0; i < methods.length; i++) {
    if (value[methods[i]]() !== today[methods[i]]()) {
      return false;
    }
  }
  return true;
}

export function isToday(value: Date): boolean {
  return isSameDay(value, new Date());
}

export function isSameYear(value1: Date, value2: Date): boolean {
  return value1.getFullYear() === value2.getFullYear();
}

export function niceDate(value: Date): string {
  const now = new Date();
  const difference = (now.getTime() - value.getTime()) / ONE_DAY;
  if (difference > 3) {
    return value.toLocaleDateString(undefined, {
      ...(!isSameYear(value, now) && { year: "numeric" }),
      month: "long",
      day: "numeric",
    });
  }
  if (isSameDay(value, now)) {
    // TODO: translate
    return "Heute";
  }
  return value
    .toLocaleDateString(undefined, { weekday: "short" })
    .replace(".", "");
}
